// Colors
$primary:       #DA0476;   
$light:         #C2C2C2;
$dark:          #040404;

// Headings style
$headings-font-family: Impact, 'Impactreg', 'Times New Roman', Times, serif;
$headings-color: $dark;
$h2-font-size: 2.5rem;

// Base style
$font-family-base: Arial, 'Times New Roman', Times, serif;

// Search Bar & Newsletter Input
$input-focus-border-color:          none;
$input-focus-box-shadow:            none;

// links
$link-color:                        $dark;
$link-decoration:                   none;
$link-hover-color:                  $primary;

// cards
$card-border-color:                 none;
$card-border-radius:                none;

// button
$btn-border-radius:           none;
