/* You can add global styles to this file, and also import other style files */
@import "scss/variable_overrides";
@import "scss/animations";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import '~lightgallery/scss/lightgallery';

.row {
    --bs-gutter-x: 0 !important;
}

.cdk-overlay-pane {
    overflow: auto;
    width: 80%;
    min-width: 250px;
    max-width: 95%;
}

.custom-dialog-container .mat-dialog-container {
    /* add your styles */
    padding: 0;
    overflow: hidden;
}